<template>
  <div class="toggle-menu" title="Open menu" @click="showMobileSidebar">
    <div class="icon">
      <CrossIcon v-show="mobileSidebarOpen" />
      <HamburgerIcon v-show="!mobileSidebarOpen" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HamburgerIcon from '~/static/images/burger.svg'
import CrossIcon from '~/static/images/cross.svg'

export default {
  name: 'ToggleMenu',
  components: { HamburgerIcon, CrossIcon },
  computed: {
    ...mapState('ui/mobile-sidebar', ['mobileSidebarOpen']),
  },
  methods: {
    showMobileSidebar() {
      if (this.mobileSidebarOpen)
        this.$store.dispatch('ui/mobile-sidebar/hideMobileSidebar')
      else this.$store.dispatch('ui/mobile-sidebar/showMobileSidebar')
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-menu {
  height: 4rem;
  width: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 20px;
    height: 17.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      fill: var(--header-color);
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .toggle-menu {
    .icon {
      width: 23px;
      height: 20.5px;
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
