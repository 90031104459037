<template>
  <header
    :class="{
      'stuck white-header': stuck,
      'white-header': hover,
      'white-header': searchOpen,
      'with-topbar': topbarTexts,
    }"
  >
    <Topbar
      v-if="topbarTexts"
      :appearance="topbarAppearance"
      :topbar-texts="topbarTexts"
      :foreground-color="topbarFgColor"
      :foreground-color-hex="topbarFgColorHex"
      :background-color="topbarBgColor"
      :background-color-hex="topbarBgColorHex"
    />
    <div
      class="main-header"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="left">
        <ToggleMenu />
        <!-- <ToggleSearch style="pointer-events: none" /> -->
      </div>
      <mp-link :to="$u('')" title="Xlash" class="logo">
        <LogoIcon />
      </mp-link>
      <div class="right">
        <HeaderText
          v-for="(headerText, i) in headerTexts"
          :key="`header-text${_uid}${i}`"
          :class="{ margin: i === headerTexts.length - 1 }"
          v-bind="headerText"
        />
        <ToggleAccount v-if="yotpoSettings.loyaltyEnabled"/>
        <ToggleHelp v-if="showCustomerSupportSidebar" />
        <ToggleCart :cart-qty="cartQty" device="mobile" />
      </div>
    </div>
    <!-- Search Results -->
    <div v-if="searchOpen" class="search-results-wrapper">
      <SearchResults />
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'
import LogoIcon from '~/static/images/logo.svg'
import ToggleMenu from '~/components/header/ToggleMenu'
// import ToggleSearch from '~/components/header/ToggleSearch'
import ToggleCart from '~/components/header/ToggleCart'
import ToggleHelp from '~/components/header/ToggleHelp'
import SearchResults from '~/components/search/SearchResults.vue'
import ToggleAccount from "@/components/header/ToggleAccount";

export default {
  name: 'Header',
  components: {
    ToggleAccount,
    ToggleMenu,
    // ToggleSearch,
    ToggleCart,
    ToggleHelp,
    LogoIcon,
    SearchResults,
  },
  props: {
    mainMenu: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    leftMenu: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    rightMenu: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    cartQty: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stuck: false,
      offset: 100,
      scrollpos: 0,
      hover: false,
    }
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.searchOpen,
      yotpoSettings: state => state.yotpo.settings,
    }),
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
    }),
    leftMenuItems() {
      return this.leftMenu.filter((it) =>
        it.countries?.length ? it.countries.includes(this.countryCode) : true
      )
    },
    rightMenuItems() {
      return this.rightMenu.filter((it) =>
        it.countries?.length ? it.countries.includes(this.countryCode) : true
      )
    },
    topbarTexts() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarTexts
    },
    topbarFgColor() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarFgColor
    },
    topbarFgColorHex() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarFgColorHex
    },
    topbarBgColor() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarBgColor
    },
    topbarBgColorHex() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarBgHex
    },
    topbarAppearance() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.topbarAppearance
    },
    headerTexts() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.headerTexts
    },
    showCustomerSupportSidebar() {
      return !this.$store.getters['storyblok/settings']
        ?.hideCustomerSupportSidebar
    },
  },
  mounted() {
    window.addEventListener('scroll', this.stickyHeader, {
      capture: true,
      passive: true,
    })

    if (this.topbarTexts)
      document.documentElement.style.setProperty(
        '--header-complete-height',
        'calc(var(--header-height) + var(--topbar-height))'
      )
    else
      document.documentElement.style.setProperty(
        '--header-complete-height',
        'var(--header-height)'
      )
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.stickyHeader)
  },
  methods: {
    linkColorCss(item) {
      return { '--link-color': color(item.linkColor, item.linkColorHex) }
    },
    stickyHeader() {
      this.scrollpos = window.scrollY
      if (this.scrollpos >= this.offset) {
        this.stuck = true
        document.documentElement.style.setProperty(
          '--header-complete-height',
          'var(--header-height)'
        )
      } else {
        this.stuck = false
        document.documentElement.style.setProperty(
          '--header-complete-height',
          'calc(var(--header-height) + var(--topbar-height))'
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  z-index: 11;
  position: relative;
  position: sticky;
  top: 0;
  overflow: visible;
  transition: transform 0.7s cubic-bezier(0.5, 0, 0, 1);

  // Default colors
  --header-background: #{$grey-lighter};
  --header-color: #{$black};
  --header-color-rgb: #{hexToRGB($black)};

  .search-results-wrapper {
    position: absolute;
    overflow-y: auto;
    max-height: 80vh;
    z-index: -1;
    width: 100%;
  }
  .main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    background: var(--header-background);
    padding: 0.8rem;
    width: 100%;
    position: relative;

    .left,
    .right {
      width: calc((100% - 8rem) / 2);
      display: flex;
      align-items: center;
      height: 100%;
    }

    .logo {
      svg {
        margin-top: 1px;
        display: block;
        height: 2.4rem;
        path {
          fill: var(--header-color);
        }
      }
    }
    .left {
      .toggle-menu {
        order: 1;
      }
      .toggle-search {
        order: 2;
      }
    }
    .right {
      justify-content: flex-end;
      position: relative;
    }
  }
  .logo {
    transition: all 0.2s ease-out;
    display: block;
    &:active {
      transform: translateY(2px);
    }
  }
  .mini-cart {
    display: none;
  }
  &.stuck.with-topbar {
    transform: translateY(-32px);
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  header {
    .main-header {
      padding-left: 2.4rem;
      padding-right: 2.4rem;

      .left,
      .right {
        gap: spacing('xsmall');
      }
      .logo {
        svg {
          height: 2.4rem;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  header {
    .main-header {
      padding-left: spacing('large');
      padding-right: spacing('large');
      .logo {
        svg {
          height: 3.2rem;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  header {
    .main-header {
      .logo {
        svg {
          height: 3.7rem;
        }
      }
    }
  }
}
</style>
