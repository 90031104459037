import { render, staticRenderFns } from "./ProductDetailsReviews.vue?vue&type=template&id=7ad68ca9&scoped=true"
import script from "./ProductDetailsReviews.vue?vue&type=script&lang=js"
export * from "./ProductDetailsReviews.vue?vue&type=script&lang=js"
import style0 from "./ProductDetailsReviews.vue?vue&type=style&index=0&id=7ad68ca9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad68ca9",
  null
  
)

export default component.exports