<template>
  <mp-link
    class="toggle-account"
    :to="$u('club')"
  >
    <client-only>
      <span v-if="isLoggedIn" class="link-text logged-in">
        {{ account.firstName }}
      </span>
      <div class="icon">
        <AccountIcon shape-rendering="geometricPrecision" />
      </div>
    </client-only>
  </mp-link>
</template>

<script>
import AccountIcon from '~/static/images/account.svg'
import account from "@/mixins/account";

export default {
  name: 'ToggleAccount',
  components: { AccountIcon },
  mixins: [account],
}
</script>

<style lang="scss" scoped>
.toggle-account {
  height: 4rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-out;
  position: relative;
  @media (hover: hover) {
    cursor: pointer;
  }
  .link-text {
    display: none;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      path:first-of-type {
        stroke: var(--header-color);
      }
      path:last-of-type {
        fill: var(--header-color);
      }
    }
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .toggle-account {
    width: auto;
    margin-right: .8rem;
    .link-text {
      display: block;
      color: var(--header-color);
      @include p--small($font-alternate);
      font-size: 1.2rem;
      margin: 0 1.2rem;
      border-bottom: 1px solid transparent;
    }
  }
  @media (hover:hover) {
    .toggle-account {
      &:hover {
        .link-text {
          border-color: var(--header-color);
        }
      }
    }
  }
}
// Laptop
@media screen and (min-width: $laptop) {
  .toggle-account {
    margin-right: 1.6rem;
    .link-text {
      font-size: 1.4rem;
    }
  }
}
</style>
