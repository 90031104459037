<template>
  <div v-if="results" class="search-results">
    <div class="mobile-input">
      <SearchInput />
    </div>
    <div v-if="suggestions.length" class="page-results">
      <div class="title">
        {{ $t('Search_Results_Suggestions') }}
      </div>
      <PillFilter :filter="suggestions" @clickedFilter="val => suggestionClick(val)" />
    </div>
    <div v-if="!suggestions.length && !results.items.length" class="page-results">
      <div class="zero-result">
        <p>
          <span>{{ $t('Search_Results_Zero_Headline') }}</span>
          {{ $t('Search_Results_Zero') }}
        </p>
      </div>
    </div>
    <div v-if="displayResults.items.length" class="product-results">
      <div class="search-header">
        <div class="title">
          <h2>{{ $t('Search_Results_Products') }}</h2>
        </div>
      </div>
      <div class="products mc--2 dc--4">
        <ProductCard
          v-for="(product, index) in displayResults.items"
          :key="index"
          :position="index + 1"
          gtm-list-name="Search"
          :findify-rid="findifyRid"
          :product="product"
          :context-search="true"
          :mobile-rounded-corners="false"
          :desktop-rounded-corners="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { processFindifyItem } from '@made-people/centra-storyblok-nuxt-shared/lib/store/findify'
import PillFilter from '~/components/pill-filter/PillFilter.vue'
import ProductCard from '~/components/product-card/ProductCard.vue'
import SearchInput from '~/components/search/SearchInput.vue'
export default {
  name: 'SearchResults',
  components: {
    PillFilter,
    ProductCard,
    SearchInput
  },
  data () {
    return {
      fallback: { items: [] }
    }
  },
  computed: {
    ...mapGetters({
      pricelist: 'frontend/pricelist'
    }),
    displayResults () {
      if (this.results.items.length) {
        return this.results
      }
      if (this.fallback.items.length) {
        return this.fallback
      }
      return null
    },
    results () {
      if (this.$store.state.ui.searchAutocompleteResult) {
        const result = JSON.parse(JSON.stringify(this.$store.state.ui.searchAutocompleteResult))
        return {
          ...result,
          items: result.items.map((item) => {
            return processFindifyItem(item, this.pricelist.currency.uri)
          })
        }
      }
      return null
    },
    suggestions () {
      return this.results.suggestions.map(it => ({
        value: it.value,
        label: it.value,
        select: false
      }))
    },
    findifyRid () {
      return this.results.meta.rid
    }
  },
  watch: {
    /**
     * Copies the latest search suggestions to fallback value
     */
    results (newVal) {
      if (newVal.items.length > 0) {
        this.fallback = newVal
      }
    }
  },
  methods: {
    suggestionClick (q) {
      this.$findifyAnalytics().sendEvent('click-suggestion', {
        rid: this.findifyRid,
        suggestion: q
      })
      this.$router.push(this.$u(`search?q=${q}`))
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  background: $grey-light;
  width: 100%;
  padding-bottom: 4rem;
  .mobile-input {
    padding: 1.6rem;
  }
  .page-results {
    padding-bottom: .8rem;
    .title {
      @include p--large;
      margin: 0;
      padding: 1.6rem;
      padding-bottom: 0;
    }
  }
  .search-header {
    display: flex;
    padding: 0 1.6rem;
    margin-bottom: 1.6rem;
    align-items: center;
    justify-content: flex-start;
    .title {
      @include p--large;
    }
  }
  .product-results {
    .products {
      padding: 0 1.6rem;
      grid-gap: 1.6rem;
      &::v-deep {
        .product-card {
          &:nth-child(1n + 5) {
            display: none;
          }
        }
      }
    }
  }
  .zero-result {
    padding: 1.6rem;
    text-align: center;
    @include p--medium;
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .search-results {
    .mobile-input {
      padding: 1.6rem 3.2rem;
    }
    .page-results {
      .title {
        padding: 1.6rem 3.2rem 0 3.2rem;
      }
      .pill-filter {
        padding: 1.2rem 2.8rem;
      }
    }
    .search-header {
      padding: 0 3.2rem;
    }
    .product-results {
      padding-bottom: 1.6rem;
      .products {
        padding: 0 3.2rem;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .search-results {
    padding: 2.4rem 4.8rem 4rem 4.8rem;
    display: flex;
    .mobile-input {
      display: none;
    }
    .page-results {
      width: 33vw;
      padding-right: 4.8rem;
      .title {
        padding: 0;
        @include h--small;
        margin: 0;
        margin-bottom: 2.4rem;
      }
      .pill-filter {
        padding: 0;
        &::v-deep {
          > span {
            margin: 0 1.2rem 1.2rem 0;
          }
        }
      }
    }
    .search-header {
      margin-bottom: 2.4rem;
      .title {
        @include h--small;
        margin: 0;
      }
    }
    .product-results {
      padding: 0;
      width: 67vw;
      .search-header {
        padding: 0;
      }
      .products {
        padding: 0;
      }
    }
    .zero-result {
      @include p--large;
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
}

</style>
