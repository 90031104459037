<template>
  <div
    class="search-input"
  >
    <div class="search-icon">
      <SearchIcon />
    </div>
    <input
      ref="searchInput"
      v-model="searchQuery"
      type="text"
      :placeholder="$t('Global_Search_Placeholder')"
      @keyup.enter="searchSubmit"
    >
    <!-- <div
      class="close-icon"
      @click="hideSearch"
    >
      <CloseIcon />
    </div> -->
  </div>
</template>

<script>
import SearchIcon from '~/assets/icons/search.svg'

export default {
  name: 'SearchInput',
  components: {
    SearchIcon
  },
  props: {
    searchOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchQuery: '',
    autocomplete: null
  }),
  watch: {
    searchQuery (newQuery) {
      clearTimeout(this.timeout)
      if (newQuery.length) {
        // Waits a short while to see if user is still typing
        this.timeout = setTimeout(() => {
          this.queryAutocomplete(newQuery)
        }, 200)
      } else {
        this.autocomplete = null
      }
    },
    searchOpen () {
      if (this.searchOpen) {
        // Only try to focus when it's open
        this.queryAutocomplete('')
      }
    }
  },
  methods: {
    hideSearch () {
      this.$store.dispatch('ui/search/hideSearch')
    },
    getVariant (item) {
      return item.variants.find(it => it.id === item.selected_variant_id)
    },
    getUrlKey (item) {
      const variant = this.getVariant(item)
      return variant.custom_fields.url_key
    },
    getColor (item) {
      if (item.color && item.color[0]) {
        return item.color[0]
      }
      return null
    },
    getSize (item) {
      if (item.size && item.size[0]) {
        return item.size[0]
      }
      return null
    },
    getAction () {
      return this.$u('search')
    },
    // closeSearch () {
    //   this.$store.dispatch('interface/hideSearch')
    // },
    queryAutocomplete (query) {
      return this.$findify().send({
        type: 'autocomplete',
        params: {
          q: query,
          item_limit: 7,
          suggestion_limit: 8
        }
      }).then((result) => {
        this.autocomplete = result
        this.$store.dispatch('ui/search/setSearchAutocompleteResult', result)
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus()
        }
        return result
      })
    },
    suggestionClick (q) {
      this.$findifyAnalytics().sendEvent('click-suggestion', {
        rid: this.rid,
        suggestion: q
      })

      this.searchQuery = q
      this.search()
    },
    searchSubmit () {
      // Separate from search() because we need to handle redirect rules
      if (this.autocomplete?.redirect?.url) {
        window.location.href = this.$u(this.autocomplete.redirect.url)
      } else {
        this.search()
      }
    },
    search () {
      const newPath = `${this.getAction()}?q=${this.searchQuery}`
      this.autocomplete = null
      this.$store.dispatch('findify/search', {
        name: 'search',
        query: { q: this.searchQuery },
        page: 1
      })
      this.$router.push(newPath)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  background-color: $white;
  display: flex;
  height: 4.8rem;
  border-radius: .4rem;
  .search-icon {
    width: 4.8rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .2s ease-out;
    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: $black;
    }
  }
  input {
    padding: 1.6rem 0;
    background-color: $white;
    @include p--medium($font-alternate);
    outline: none;
    border: 0;
    color: $black;
    width: calc(100% - 5.6rem - 5.6rem);
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .search-input {
    background-color: transparent;
    border: 1px solid rgba(var(--header-color-rgb),var(--search-input-border-opacity));
    height: 3.8rem;
    border-radius: .4rem;
    justify-content: space-between;
    .search-icon {
      order: 2;
      svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--header-color);
      }
    }
    input {
      order: 1;
      background-color: transparent;
      @include p--small($font-alternate);
      width: 100%;
      padding-left: 1.6rem;
      color: var(--header-color);
      width: 10rem;
      transition: width 0.2s ease-out;
      &::placeholder {
        color: var(--header-color);
        opacity: 0.5;
      }
      &:focus {
        width: 15rem;
      }
    }
  }
}
</style>
