<template>
  <div class="country-selector-sidebar">
    <ModalHeader
      title="Shopping From"
      icon-position="right"
      @close="closeSidebar"
    />
    <div class="wrapper" :class="{ loading: loading }">
      <h4>{{ $t('Country_Selector_Sidebar_Shopping_From') }}</h4>
      <div class="select-boxes">
        <div class="select-box">
          <span class="label">
            {{ $t('Global_Country') }}
          </span>
          <div class="styled-selectbox">
            <div class="selected-value">
              <img
                :src="'/flags/market/' + countryCode.toLowerCase() + '.svg'"
                :alt="currentCountry.name"
                loading="lazy"
                height="10"
                width="16"
              />
              <div class="label">
                {{ currentCountry.name }}
              </div>
            </div>
            <span class="chevron">
              <ChevronIcon />
            </span>
            <select v-model="countryCode" @change="countryChanged">
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.country"
                :selected="country.country === countryCode"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="select-box">
          <span class="label">
            {{ $t('Global_Language') }}
          </span>
          <div class="styled-selectbox">
            <div class="selected-value">
              <div class="label">
                {{ currentLanguage.name }}
              </div>
            </div>
            <span class="chevron">
              <ChevronIcon />
            </span>
            <select v-model="languageCode" @change="countryLanguageChanged">
              <option
                v-for="(language, index) in languages"
                :key="index"
                :value="language.language"
              >
                {{ language.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="currentMarketInfo" class="market-info">
        <p
          v-if="
            currentMarketInfo.deliveryTimeLabel &&
            currentMarketInfo.deliveryTimeInfo
          "
          class="market-info-item"
        >
          <span class="label">{{ currentMarketInfo.deliveryTimeLabel }}</span>
          <span class="value">{{ currentMarketInfo.deliveryTimeInfo }}</span>
        </p>
        <p
          v-if="
            currentMarketInfo.deliveryCostLabel &&
            currentMarketInfo.deliveryCostInfo
          "
          class="market-info-item"
        >
          <span class="label">{{ currentMarketInfo.deliveryCostLabel }}</span>
          <span class="value">{{ currentMarketInfo.deliveryCostInfo }}</span>
        </p>
        <p v-if="currentMarketInfo.currencyLabel" class="market-info-item">
          <span class="label">{{ currentMarketInfo.currencyLabel }}</span>
          <span class="value">{{ currentCurrency }}</span>
        </p>
      </div>
      <div class="actions">
        <button class="button solid medium red" @click="changeSettings">
          <span class="label">
            {{ $t('Country_Selector_Sidebar_Change') }}
          </span>
        </button>
        <button class="button outline medium black" @click="closeSidebar">
          <span class="label">
            {{ $t('Country_Selector_Sidebar_Keep_Settings') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'
import ModalHeader from '~/components/modal/modal-header/ModalHeader'

export default {
  name: 'CountrySelectorSidebar',
  components: {
    ChevronIcon,
    ModalHeader,
  },
  props: {
    markets: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },

    siteLangSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      countryCode: this.$store.getters['frontend/currentCountryCode'],
      languageCode: this.$store.getters['frontend/currentLanguageCode'],
      currentMarketInfo: {},
      currentCurrency: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      marketSettings: 'storyblok/marketSettings',
      getMarketSettings: 'storyblok/getMarketSettings',
      getMarketByCountryCode: 'frontend/getMarketByCountryCode',
      getCurrencyCodeByCountryCode: 'frontend/getCurrencyCodeByCountryCode',
      pricelist: 'frontend/pricelist',
    }),
    currentLanguage() {
      return this.$store.state.frontend.languages.find(
        (x) => x.language === this.languageCode
      )
    },
    currentCountry() {
      return this.$store.state.frontend.countries.find(
        (x) => x.country === this.countryCode.toUpperCase()
      )
    },
    currentMarket() {
      return this.markets.find((it) => it.countries.includes(this.countryCode))
    },
    currency() {
      return this.pricelist.currency.currency
    },
    languages() {
      return this.$store.state.frontend.languages
    },
  },
  created() {
    const settings = this.getMarketSettings(
      this.languageCode,
      this.getMarketByCountryCode(this.countryCode)
    )
    if (settings) {
      this.currentMarketInfo = settings.data[0]
    }
    this.currentCurrency = this.currency
  },
  methods: {
    countryChanged() {
      this.languageCode = this.currentCountry.language ?? this.$config.defaultLocale
      return this.countryLanguageChanged()
    },
    async countryLanguageChanged() {
      await this.$store.dispatch('storyblok/fetchCartUSPs', {
        countryCode: this.countryCode,
        languageCode: this.languageCode,
      })
      const settings = this.getMarketSettings(
        this.languageCode,
        this.getMarketByCountryCode(this.countryCode)
      )
      if (settings) {
        this.currentMarketInfo = settings.data[0]
      }
      this.currentCurrency = this.getCurrencyCodeByCountryCode(this.countryCode)
    },

    /**
     * Triggered when 'close' event is emitted by the ModalHeader
     */
    closeSidebar() {
      this.$store.dispatch('ui/country-selector/hideCountrySelector')
    },

    getCurrentSlug(prefix) {
      const slugParts = this.$route.path.split('/')
      slugParts[1] = prefix
      return slugParts.join('/')
    },

    changeSettings() {
      const countryObject = this.$store.getters[
        'frontend/countriesSorted'
      ].find((x) => x.country === this.countryCode)
      const slugParts = [this.countryCode]
      if (this.languageCode !== countryObject.language) {
        slugParts.push(this.languageCode)
      }
      const slug = slugParts.join('-').toLowerCase()
      window.location.href = this.getCurrentSlug(slug)
    },
  },
}
</script>

<style lang="scss" scoped>
.country-selector-sidebar {
  width: calc(100% - 3.2rem);
  height: calc(var(--vh, 1vh) * 100);
  max-width: 40rem;
  z-index: 100;
  overflow: hidden;
  background: $white;

  .wrapper {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 5.6rem);
    &.loading {
      opacity: 0.5;
    }
  }
  h4 {
    @include h--medium;
    margin-top: 0;
    display: none;
  }
  .select-boxes {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1.6rem;
    margin-bottom: 1.6rem;
    .styled-selectbox {
      width: 100%;

      .selected-value {
        width: calc(100% - 4rem);
        justify-content: flex-start;
        padding: 0 0 0 1.6rem;

        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .market-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;
    margin: 2.4rem 0;
    background: $page-background;
    padding: 1.6rem;
    .market-info-item {
      margin: 0;
      .label {
        display: block;
        @include h--tiny;
        margin: 0.2rem 0;
        text-transform: none;
      }
      .value {
        display: block;
        @include p--medium;
      }
    }
  }
  .actions {
    .button {
      width: 100%;
      margin-top: 1.6rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
}
</style>
