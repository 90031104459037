<!-- LowestPrice.vue -->
<template>
  <div
    class="lowest-price-wrapper"
    @mouseleave="showLowestPriceInfo = false"
    @click="$store.dispatch('ui/lowest-price-banner/showLowestPriceBanner')"
  >
    <span class="lowest-price">
      {{
        $t('PDP_Lowest_Price', {
          lowestPrice: product.lowestPrice[0].price,
          regularPrice: product.priceBeforeDiscount,
        })
      }}
      <Information @click="showLowestPriceInfo = !showLowestPriceInfo" />
    </span>
    <transition name="fade">
      <span v-if="showLowestPriceInfo" class="lowest-price-info">
        {{ $t('PDP_Lowest_Price_Info') }}
      </span>
    </transition>
  </div>
</template>

<script>
import Information from '~/assets/icons/information.svg'

export default {
  name: 'LowestPrice',
  components: {
    Information,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLowestPriceInfo: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.lowest-price-wrapper {
  padding: 0 var(--side-padding);
  margin-top: -16px;
  margin-bottom: 24px;
  .lowest-price {
    @include p--small;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    opacity: 0.5;

    svg {
      height: 14px;
      width: 14px;
    }
  }
  .lowest-price-info {
    display: none;
  }
}
@media screen and (min-width: $tablet-landscape) {
  .lowest-price-wrapper {
    margin-top: -8px;
    margin-bottom: 28px;
    .lowest-price {
      cursor: default;
      @include p--medium;

      svg {
        cursor: pointer;
      }
    }
    .lowest-price-info {
      display: flex;
      @include p--medium;
      background-color: $grey;
      margin-top: 14px;
      max-width: 350px;
      padding: 16px;
    }
  }
}
</style>
