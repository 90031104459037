<template>
  <div
    id="lipscore-rating"
    class="product-details-reviews"
    :data-ls-product-name="productName"
    data-ls-brand="xlash"
    :data-ls-product-id="productId"
    :data-ls-product-url="productUrl"
    data-ls-readonly="true"
  />
</template>
<script>
import { lipscoreInitWidgetsDebounced } from '~/lib/lipscore'

export default {
  name: 'ProductDetailsReviews',
  props: {
    productId: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    productUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    lipscoreInitWidgetsDebounced()
  },
}
</script>
<style lang="scss" scoped>
.product-details-reviews {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 1.2rem;

  @media screen and (min-width: 768px) {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0;
  }
}
</style>
