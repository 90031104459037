import StoryblokStory, {getMarketSettingsId} from '@made-people/centra-storyblok-nuxt-shared/lib/store/storyblok'
import { isUnknownAxiosResponseException } from '@made-people/axios-response-guards'

const getCountrySpecificInformationId = (language, marketId) =>
  ['country-information', marketId, language].join('-')

export default {
  ...StoryblokStory,
  mutations: {
    ...StoryblokStory.mutations,
    story: (state, payload) => {
      // removing unused data from stories before comitting to state
      // in order to save computing time for vue as it will not create observables
      const storyblokFieldsToRemove = [
        'created_at',
        'published_at',
        'default_full_slug',
        'sort_by_date',
        'tag_list',
        'meta_data',
        'group_id',
        'first_published_at',
        'release_id',
        'lang',
        'path',
        'translated_slugs',
        'alternates',
      ]

      storyblokFieldsToRemove.forEach((field) => {
        delete payload.data[field]
      })

      delete payload.data?.content?._editable
      state.stories = [payload].concat(
        state.stories.filter((x) => x.id !== payload.id)
      )
    },
  },
  actions: {
    fetchStory({ commit, rootState, getters }, { id, params }) {
      let lookupId = id
      const link = getters.getLinkForSlug(id)
      if (link) {
        lookupId = link.id
      }
      params = params || {}
      params.language =
        params.language || rootState.frontend.currentLanguageCode
      return this.$storyapi
        .get(`cdn/stories/${lookupId}`, { params })
        .then((response) => {
          commit('story', {
            id: lookupId,
            data: response.data.story,
          })
          return response.data.story
        })
        .catch((error) => {
          if (isUnknownAxiosResponseException(error)) {
            console.error(
              `Failed to read storyblok story with id=${lookupId}: ${JSON.stringify(
                {
                  response: {
                    statusCode: error.response.status,
                    responseBody: error.response.data,
                  },
                }
              )}`
            )
            return undefined
          }
          console.error(
            `Failed to read storyblok story with id=${lookupId}: ${error}`
          )
          return undefined
        })
    },

    /**
     * Used by the shared lib
     */
    fetchCollectionContent ({ commit, rootState }, collection) {
      const prefix = rootState.frontend.currentLanguageCode === 'en' ? '' : rootState.frontend.currentLanguageCode + '/'
      return this.$storyapi.get('cdn/stories', {
        params: {
          starts_with: prefix + 'shared-content/product-page/collection-content',
          filter_query: {
            component: {
              in: 'ProductCollectionContent'
            },
            collection: {
              in: collection
            }
          }
        }
      }).then((response) => {
        commit('story', {
          id: getCollectionId(collection),
          data: response.data.stories[0]
        })
        return response.data.stories[0]
      }).catch((error) => {
        if (isUnknownAxiosResponseException(error)) {
          console.error(
            `Failed to fetch collection content: ${JSON.stringify(
              {
                response: {
                  statusCode: error.response.status,
                  responseBody: error.response.data,
                },
              }
            )}`
          )
          return undefined
        }
        console.error(
          `Failed to fetch collection content: ${error}`
        )
        return undefined
      })
    },

    fetchCartUSPs ({ commit, rootState, rootGetters }, options) {
      options = options || {}
      let market = ''
      if (options.countryCode) {
        // Look up market from country
        market = rootGetters['frontend/getMarketByCountryCode'](options.countryCode)
      }
      if (!market) {
        market = rootGetters['frontend/currentMarketId']
      }

      // 'en' is the default language and storyblok is weird with localization
      const languageCode = options.languageCode || rootState.frontend.currentLanguageCode
      const prefix = languageCode === 'en' ? ''
        : languageCode + '/'

      return this.$storyapi.get('cdn/stories', {
        params: {
          starts_with: prefix + 'shared-content/cart-usps',
          filter_query: {
            markets: {
              in_array: market
            }
          }
        }
      }).then((response) => {
        const data = response.data.stories.flatMap(story => story?.content)
          .filter(x => x !== undefined)
        commit('story', {
          id: getCartUSPsId(languageCode, market),
          data
        })
        const settings = response.data.stories.flatMap(story => story?.content)
          .filter(x => x !== undefined)
        commit('story', {
          id: getMarketSettingsId(languageCode, market),
          data: settings
        })
        return data
      }).catch((error) => {
        if (isUnknownAxiosResponseException(error)) {
          console.error(
            `Failed to fetch cart usps: ${JSON.stringify(
              {
                response: {
                  statusCode: error.response.status,
                  responseBody: error.response.data,
                },
              }
            )}`
          )
          return undefined
        }
        console.error(
          `Failed to cart usps: ${error}`
        )
        return undefined
      })
    },
  },
  getters: {
    ...StoryblokStory.getters,
    getCountryInformation: (state) => (languageCode, marketId) => {
      return (
        state.stories.find(
          (it) =>
            it.id === getCountrySpecificInformationId(languageCode, marketId)
        ) || { id: null, data: [] }
      )
    },
  },
}
